import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { configCommission, updateComissionPaymentServiceConfig } from './apis/requestsApi';
import Swal from 'sweetalert2';
import { PaymentService } from './core/CommissionPaymentService.Model';

const CommissionPaymentService: React.FC = () => {
  const intl = useIntl();

  const [paymentServices, setPaymentServices] = useState<PaymentService[]>([]);
  const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);
  const [filteredPaymentServices, setFilteredPaymentServices] = useState<PaymentService[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  // Estados para las comisiones editables
  const [datalogicCommissionValues, setDatalogicCommissionValues] = useState<Record<number, string | null>>({});
  const [totalCommisionEqualityValues, setTotalCommisionEqualityValues] = useState<Record<number, string | null>>({});
  const [totalCustomerCommissionValues, setTotalCustomerCommissionValues] = useState<Record<number, string | null>>({});
  const [totalCommissionValues, setTotalCommissionValues] = useState<Record<number, string | null>>({});

  const [isFavoriteValues, setIsFavoriteValues] = useState<Record<number, boolean>>({});
  const [visibleValues, setVisibleValues] = useState<Record<number, boolean>>({});
  const [commissionError, setCommissionError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setLoading(true);
      try {
        const { paymentServices } = await configCommission();
        setPaymentServices(paymentServices);

        // Inicializar el estado editable para datalogic con los valores actuales
        const initialDatalogic: Record<number, string> = {};
        paymentServices.forEach((service: PaymentService) => {
          initialDatalogic[service.id] = service.datalogicCommission?.toString() || "0";
        });
        setDatalogicCommissionValues(initialDatalogic);

        const categorySet = new Set<string>(
          paymentServices.map((item: { category: any; }) => item.category)
        );
        const categories = Array.from(categorySet).sort((a, b) => a.localeCompare(b));
        setUniqueCategories(categories);
        setSelectedCategory(categories[0]);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleBrandChange = (brand: string) => {
    setSelectedCategory(brand);
  };

  useEffect(() => {
    const filtered = paymentServices.filter(
      (service) => service.category === selectedCategory
    );
    setFilteredPaymentServices(filtered);
  }, [selectedCategory, paymentServices]);

  /**
   * Valida que:
   *   datalogicCommission (editable ahora) + equalityCommission + customerCommission === totalCommission
   */
  const isValidTotalCommission = (
    service: PaymentService,
    datalogicValue: string,
    equalityValue: string,
    customerValue: string,
    totalValue: string
  ) => {
    const datalogic = parseFloat(datalogicValue);
    const equality = parseFloat(equalityValue);
    const customer = parseFloat(customerValue);
    const total = parseFloat(totalValue);
    const calculatedTotal = datalogic + equality + customer;
    const difference = parseFloat((calculatedTotal - total).toFixed(2));
    const isValid = Math.abs(difference) < 0.01;
    return { isValid, difference };
  };

  /**
   * Actualiza el valor de la comisión editada y valida la suma total.
   * Se soportan los siguientes tipos:
   *   - 'datalogicCommission'
   *   - 'equalityCommission'
   *   - 'customerCommission'
   *   - 'totalCommission'
   */
  const validateAndSet = (
    service: PaymentService,
    type: 'datalogicCommission' | 'equalityCommission' | 'customerCommission' | 'totalCommission',
    value: string
  ) => {
    // Obtener los valores actuales (del estado o del objeto original)
    let datalogicValue =
      datalogicCommissionValues[service.id] ?? (service.datalogicCommission?.toString() ?? "0");
    let equalityValue =
      totalCommisionEqualityValues[service.id] ?? (service.equalityCommission?.toString() ?? "0");
    let customerValue =
      totalCustomerCommissionValues[service.id] ?? (service.customerCommission?.toString() ?? "0");
    let totalValue =
      totalCommissionValues[service.id] ?? (service.totalCommission?.toString() ?? "0");

    // Actualiza el valor según el campo modificado
    if (type === 'datalogicCommission') {
      datalogicValue = value;
      setDatalogicCommissionValues((prev) => ({ ...prev, [service.id]: value }));
    } else if (type === 'equalityCommission') {
      equalityValue = value;
      setTotalCommisionEqualityValues((prev) => ({ ...prev, [service.id]: value }));
    } else if (type === 'customerCommission') {
      customerValue = value;
      setTotalCustomerCommissionValues((prev) => ({ ...prev, [service.id]: value }));
    } else if (type === 'totalCommission') {
      totalValue = value;
      setTotalCommissionValues((prev) => ({ ...prev, [service.id]: value }));
    }

    const validation = isValidTotalCommission(service, datalogicValue, equalityValue, customerValue, totalValue);
    if (!validation.isValid) {
      const message = `La suma de las comisiones para ${service.serviceProvider} es ${
        validation.difference > 0 ? 'mayor' : 'menor'
      } que el total por ${Math.abs(validation.difference)}.`;
      Swal.fire({
        icon: 'error',
        title: '¡Atención!',
        text: message,
      });
      setCommissionError(true);
    } else {
      setCommissionError(false);
    }
  };

  const allCommissionsAreValid = () => {
    return filteredPaymentServices.every((service) => {
      const datalogicValue =
        datalogicCommissionValues[service.id] ?? (service.datalogicCommission?.toString() ?? "0");
      const equalityValue =
        totalCommisionEqualityValues[service.id] ?? (service.equalityCommission?.toString() ?? "0");
      const customerValue =
        totalCustomerCommissionValues[service.id] ?? (service.customerCommission?.toString() ?? "0");
      const totalValue =
        totalCommissionValues[service.id] ?? (service.totalCommission?.toString() ?? "0");
      return isValidTotalCommission(service, datalogicValue, equalityValue, customerValue, totalValue).isValid;
    });
  };

  const handlePaymentServiceUpdate = () => {
    const relevantFields = filteredPaymentServices.map((service) => ({
      id: service.id,
      // Ahora se toma la comisión de Datalogic desde el estado editable
      datalogicCommission: datalogicCommissionValues[service.id] || service.datalogicCommission,
      equalityCommission: totalCommisionEqualityValues[service.id] || service.equalityCommission,
      customerCommission: totalCustomerCommissionValues[service.id] || service.customerCommission,
      totalCommission: totalCommissionValues[service.id] || service.totalCommission,
      isFavorite: isFavoriteValues[service.id] ?? service.isFavorite,
      visible: visibleValues[service.id] ?? service.visible,
    }));

    const formattedData = { PaymentServices: relevantFields };

    Swal.fire({
      html: `
        <div class="spinner-container" style="overflow: hidden;">
          <i class="fas fa-spinner fa-spin"></i>
          <span>Actualizando configuración...</span>
        </div>
      `,
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    updateComissionPaymentServiceConfig(formattedData)
      .then((response) => {
        console.log('PaymentServices updated successfully:', response);
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: '¡Actualizado!',
          text: 'La configuración se completó con éxito.',
        });
      })
      .catch((error) => {
        console.error('Failed to update PaymentServices:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error al actualizar. Por favor intenta de nuevo.',
        });
      });
  };

  if (error) {
    return <div>Ocurrió un error</div>;
  }
  if (loading) return <p>Cargando...</p>;

  return (
    <div>
      <div className="card mb-5 mb-xl-10 mt-10" id="kt_profile_details_view">
        <div className="card-header">
          <h3 className="card-title">Configurar comisiones de Pago de servicios</h3>
        </div>
        <div className="card-body">
          {/* Pestañas de categorías */}
          <ul className="nav nav-tabs ml-3 mr-3" id="myTab" role="tablist">
            {uniqueCategories.map((category, index) => (
              <li className="nav-item" key={index}>
                <a
                  className={`nav-link cursor-pointer ${
                    selectedCategory === category ? 'active bg-primary text-white' : ''
                  }`}
                  onClick={() => handleBrandChange(category)}
                >
                  {category}
                </a>
              </li>
            ))}
          </ul>

          {/* Tabla de configuración */}
          <div className="tab-content ml-3 mr-3 mt-10" id="myTabContent">
            <table className="table">
              <thead>
                <tr>
                  <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_PROVIDER' })}</th>
                  <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_SKU' })}</th>
                  <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_COVERAGE' })}</th>
                  <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_TOTALCOMMISSION' })}</th>
                  <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_DATALOGICCOMMISSION' })}</th>
                  <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_EQUALITYCOMMISSION' })}</th>
                  <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_PARTNERCOMMISSION' })}</th>
                  <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_ISFAVORITE' })}</th>
                  <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_ISVISIBLE' })}</th>
                </tr>
              </thead>
              <tbody>
                {filteredPaymentServices.map((service, index) => (
                  <tr key={index}>
                    <td>{service.serviceProvider}</td>
                    <td>{service.sku}</td>
                    <td>{service.coverageArea}</td>
                    <td>
                      <input
                        type="number"
                        name="totalCommission"
                        className="form-control"
                        min={0}
                        value={
                          totalCommissionValues[service.id] ??
                          (service.totalCommission?.toString() ?? "0")
                        }
                        onChange={(e) =>
                          setTotalCommissionValues((prev) => ({
                            ...prev,
                            [service.id]: e.target.value,
                          }))
                        }
                        onBlur={(e) =>
                          validateAndSet(service, 'totalCommission', e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="datalogicCommission"
                        className="form-control"
                        min={0}
                        value={
                          datalogicCommissionValues[service.id] ??
                          (service.datalogicCommission?.toString() ?? "0")
                        }
                        onChange={(e) =>
                          setDatalogicCommissionValues((prev) => ({
                            ...prev,
                            [service.id]: e.target.value,
                          }))
                        }
                        onBlur={(e) =>
                          validateAndSet(service, 'datalogicCommission', e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="equalityCommission"
                        className="form-control"
                        min={0}
                        value={
                          totalCommisionEqualityValues[service.id] ??
                          (service.equalityCommission?.toString() ?? "0")
                        }
                        onChange={(e) =>
                          setTotalCommisionEqualityValues((prev) => ({
                            ...prev,
                            [service.id]: e.target.value,
                          }))
                        }
                        onBlur={(e) =>
                          validateAndSet(service, 'equalityCommission', e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="customerCommission"
                        className="form-control"
                        min={0}
                        value={
                          totalCustomerCommissionValues[service.id] ??
                          (service.customerCommission?.toString() ?? "0")
                        }
                        onChange={(e) =>
                          setTotalCustomerCommissionValues((prev) => ({
                            ...prev,
                            [service.id]: e.target.value,
                          }))
                        }
                        onBlur={(e) =>
                          validateAndSet(service, 'customerCommission', e.target.value)
                        }
                      />
                    </td>
                    <td className="text-center">
                      <div className="custom-control custom-checkbox d-inline-block">
                        <input
                          type="checkbox"
                          id={`isFavorite-${service.id}`}
                          name="isFavorite"
                          className="form-check-input border border-2 rounded"
                          style={{ backgroundColor: 'var(--kt-primary)' }}
                          checked={isFavoriteValues[service.id] ?? service.isFavorite}
                          onChange={(e) =>
                            setIsFavoriteValues((prev) => ({
                              ...prev,
                              [service.id]: e.target.checked,
                            }))
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`isFavorite-${service.id}`}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <div className="custom-control custom-checkbox d-inline-block">
                        <input
                          type="checkbox"
                          id={`visible-${service.id}`}
                          name="visible"
                          className="form-check-input border border-2 rounded"
                          style={{ backgroundColor: 'var(--kt-primary)' }}
                          checked={visibleValues[service.id] ?? service.visible}
                          onChange={(e) =>
                            setVisibleValues((prev) => ({
                              ...prev,
                              [service.id]: e.target.checked,
                            }))
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`visible-${service.id}`}
                        ></label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-12 text-right">
              <button
                style={{ float: 'right' }}
                className="btn btn-primary mt-3"
                onClick={handlePaymentServiceUpdate}
                disabled={commissionError || !allCommissionsAreValid()}
              >
                Guardar Configuración {selectedCategory}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CommissionPaymentService };
