import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { fetchCatalogs, saveTransaction, uploadDirectly } from './apis/rechargesAPI'
import { useIntl } from 'react-intl'
import {
  IMoneyBag, moneyBagInitValues as initialValues, TransactionsMoneybagCustomer,
  ITypesMoneybagCustomers, ITypesPaymentsMoneybagCustomer
} from './core/Recharge.Model'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Swal, { SweetAlertOptions } from 'sweetalert2';


import { LastRecordPassport } from './tableLastRecordsPassport';
import { type } from 'os';

const Passport: React.FC = () => {
  const intl = useIntl()
  const { customerId } = useParams()
  const [transactions, setTransactions] = useState<TransactionsMoneybagCustomer[]>([]);
  const [types, setTypes] = useState<ITypesMoneybagCustomers[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<ITypesPaymentsMoneybagCustomer[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [saldoRecargas, setSaldoRecargas] = useState<number | 0>(0);
  const [saldoPagos, setSaldoPagos] = useState<number | 0>(0);
  const [saldoCommission, setSaldoCommission] = useState<number | 0>(0);
  const [registroExitoso, setRegistroExitoso] = useState(false);

  const [selectedType, setSelectedType] = useState<number | null>(null);
  interface CustomSweetAlertOptions extends SweetAlertOptions {
    onOpen?: () => void;
  }

  const moneyBagSchema = Yup.object().shape({
    typesMoneybagCustomersId: Yup.number()
      .integer()
      .moreThan(0, 'El tipo de movimiento es obligatorio.')
      .required('El tipo de movimiento es obligatorio.'),

    typesTransactionsMoneybagCustomerId: Yup.number()
      .integer()
      .moreThan(0, 'El tipo de transacción es obligatorio.')
      .required('El tipo de transacción es obligatorio.'),

    accountBank: Yup.string()
      .required('La cuenta bancaria es obligatoria.'),

    typesPaymentsMoneybagCustomerId: Yup.number()
      .integer()
      .moreThan(0, 'El  tipo de pago es obligatorio')
      .required('El  tipo de pago es obligatorio.'),

    amount: Yup.number()
      .typeError('El monto debe ser un número.')
      .required('El monto es obligatorio.'),

    noAut: Yup.string()
      .required('El número de autorización es obligatorio.'),

    datePayment: Yup.date()
      .required('La fecha de pago es obligatoria.'),




    note: Yup.string()
      .when('typesTransactionsMoneybagCustomerId', {
        is: 2,
        then: Yup.string().required('La nota es obligatoria cuando se realiza un cargo.'),
        otherwise: Yup.string()
      }),

  });


  useEffect(() => {

    const fetchData = async () => {


      try {

        const { transactions, types, paymentTypes, saldoRecargas, saldoPagos,saldoCommission } = await fetchCatalogs(Number(customerId))

        setSaldoRecargas(saldoRecargas);
        setSaldoPagos(saldoPagos);

        setSaldoCommission(saldoCommission)

        setTransactions(transactions);


        setTypes(types);
        setPaymentTypes(paymentTypes)

      } catch (err: any) {
        console.log(err)

      }
    }

    fetchData()
  }, []) // Arreglo de dependencias vacío



  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      // Verifica que el archivo no supere los 2 MB
      if (file.size > 2 * 1024 * 1024) {
        Swal.fire({
          icon: 'error',
          title: 'Archivo demasiado grande',
          text: 'El comprobante no debe ser mayor a 2 MB.'
        });
        // Reiniciar el input de archivo y establecer el valor en null
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        setSelectedFile(null);
        formik.setFieldValue('vaucher', null);
        return;
      }
      // Si el archivo es válido, lo establecemos
      setSelectedFile(file);
      formik.setFieldValue('vaucher', file);
    } else {
      setSelectedFile(null);
    }
  };
  
  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTypeId = parseInt(event.target.value, 10);
    setSelectedType(selectedTypeId);
    formik.handleChange(event); // Actualizar formik.values
  };





  const formik = useFormik<IMoneyBag>({
    initialValues,
    validationSchema: moneyBagSchema,
    onSubmit: async (values) => {



      console.log(selectedFile)

      // Pregunta de confirmación
      Swal.fire({
        title: '¿Estás seguro?',
        text: "¿Deseas confirmar este movimiento?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, confirmar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let vaucher = null;
          if (selectedFile) {
            vaucher = await uploadDirectly(selectedFile);
            if (!vaucher) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No se pudo subir el voucher!',
              });
              formik.setErrors({
                ...formik.errors,
                vaucher: 'No se pudo subir el voucher.'
              });
              return;
            }
          }
          const {
            typesMoneybagCustomersId, typesTransactionsMoneybagCustomerId,
            accountBank, typesPaymentsMoneybagCustomerId, amount,
            noAut, datePayment, note
          } = values;

          const json = {
            customerId, typesMoneybagCustomersId, typesTransactionsMoneybagCustomerId,
            accountBank, typesPaymentsMoneybagCustomerId, amount,
            noAut, datePayment, note, vaucher
          };


          // Mostrar SweetAlert con spinner
          Swal.fire({
            html: `
            <div class="spinner-container" style="overflow: hidden;">
                <i class="fas fa-spinner fa-spin"></i>
                <span> Actualizando Saldo</span>
            </div>
            `,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: false,
            showCloseButton: false,
            onBeforeOpen: () => {
              Swal.showLoading(); // Esto mostrará una animación de carga adicional
            },
          } as CustomSweetAlertOptions);


          try {
            const data = await saveTransaction(json);
            setSaldoRecargas(data.saldoRecargas);
            setSaldoPagos(data.saldoPagos);
            setSaldoCommission(data.commissionTotal);
            setRegistroExitoso(true);
            Swal.close();
            // SweetAlert de movimiento realizado
            Swal.fire({
              icon: 'success',
              title: 'Movimiento realizado',
              text: 'El movimiento ha sido registrado con éxito.',
            });

            // Reiniciar el formulario
            formik.resetForm();
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          } catch (error) {
            // Ocultar el loading
            Swal.close();

            // Mostrar SweetAlert de error
            Swal.fire({
              icon: 'error',
              title: 'Error al realizar la transacción',
              text: (error as Error)?.message || 'No se pudo completar la transacción. Por favor, inténtalo nuevamente más tarde.'
            });

            // Manejar el error aquí y establecer un error en el campo vaucher
            console.error('Error al realizar la transacción:', error);
            formik.setErrors({
              ...formik.errors,
              vaucher: 'Error al realizar la transacción.'
            });
          }

        }
      });

    },
  });


  return (
    <>

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>
                  Actualizar Saldo
                </h3>
              </div>
            </div>

            <div className='card-body '>
              <div className="row  justify-content-left">
                <div className="col-md-5 col-xs-12 mr-md-2 mb-3">
                  <form onSubmit={formik.handleSubmit} noValidate className='form'>

                    {/*Primer row*/}
                    <div className="row mt-5">
                      <div className="col-md-5 col-xs-12 mr-md-2 mb-3">
                        <label className="form-label">Saldo para</label>
                        <select
                          className="form-select form-select-solid"
                          name="typesMoneybagCustomersId"
                          value={formik.values.typesMoneybagCustomersId}
                          onChange={(e) => {
                            handleTypeChange(e);
                          }}
                        >
                          <option value="">Selecciona una Opción</option>
                          {types.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                        {formik.touched.typesMoneybagCustomersId && formik.errors.typesMoneybagCustomersId && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.typesMoneybagCustomersId}</div>
                          </div>
                        )}
                      </div>


                      <div className="col-md-5 col-xs-12">
                        <label className="form-label">Tipo de Movimiento</label>
                        <select
                          className="form-select form-select-solid"
                          name="typesTransactionsMoneybagCustomerId"
                          value={formik.values.typesTransactionsMoneybagCustomerId}
                          onChange={formik.handleChange}
                          disabled={!selectedType} // Deshabilita el segundo select si no hay un tipo seleccionado en el primero
                        >
                          <option value="">Selecciona una Opción</option>
                          {transactions
                            .filter((transaction) => {
                              if (selectedType === 2 || selectedType === 3) {
                                return transaction.id === 1 || transaction.id === 2;
                              } else if (selectedType === 4) {
                                return transaction.id === 3 || transaction.id === 4;
                              }
                              return false;
                            })
                            .map((transaction) => (
                              <option key={transaction.id} value={transaction.id}>
                                {transaction.name}
                              </option>
                            ))}
                        </select>
                        {formik.touched.typesTransactionsMoneybagCustomerId && formik.errors.typesTransactionsMoneybagCustomerId && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.typesTransactionsMoneybagCustomerId}</div>
                          </div>
                        )}
                      </div>

                    </div>

                    {/*Segundo row*/}
                    <div className="row  mt-8">
                      <div className="col-md-5 col-xs-12 mr-md-2 mb-3">
                        <label className='form-label'>Cuenta bancaria</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""

                          {...formik.getFieldProps('accountBank')}
                        />

                        {formik.touched.accountBank && formik.errors.accountBank && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.accountBank}</div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-5 col-xs-12 mr-md-2 mb-3">
                        <label className='form-label'>Forma de pago</label>
                        <select className='form-select form-select-solid' {...formik.getFieldProps('typesPaymentsMoneybagCustomerId')}>
                          <option value="">Selecciona una Opción</option>
                          {paymentTypes.map((paymentType) => (
                            <option key={paymentType.id} value={paymentType.id}>{paymentType.name}</option>
                          ))}
                        </select>
                        {formik.touched.typesPaymentsMoneybagCustomerId && formik.errors.typesPaymentsMoneybagCustomerId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.typesPaymentsMoneybagCustomerId}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/*Tercer row*/}
                    <div className="row mt-8">
                      <div className="col-md-5 col-xs-12 mr-md-2 mb-3">
                        <label className='form-label'>Monto</label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          {...formik.getFieldProps('amount')}
                        />
                        {formik.touched.amount && formik.errors.amount && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.amount}</div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-5 col-xs-12">
                        <label className='form-label'>No de Autorización</label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          {...formik.getFieldProps('noAut')}
                        />

                        {formik.touched.noAut && formik.errors.noAut && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.noAut}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/*4to row*/}
                    <div className="row mt-8">
                      <div className="col-md-5 col-xs-12 mr-md-2 mb-3">
                        <label className='form-label'>Fecha</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="dd/mm/yyyy"
                          {...formik.getFieldProps('datePayment')}
                        />

                        {formik.touched.datePayment && formik.errors.datePayment && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{String(formik.errors.datePayment)}</div>
                          </div>
                        )}

                      </div>

                      <div className="col-md-5 col-xs-12 ">
                        <label className="form-label">Comprobante</label>
                        <div className="custom-file">
                          <input type="file"
                            className="custom-file-input"
                            id="customFile"
                            ref={fileInputRef}
                            accept="image/*"
                            style={{ width: "100%" }}  // Nota la corrección aquí
                            onChange={(event) => {
                              const file = event.currentTarget.files;
                              if (file && file.length > 0) {
                                formik.setFieldValue('vaucher', file[0]);
                                handleFileChange(event);
                              }
                            }}


                          />
                          <label className="custom-file-label" htmlFor="customFile">Elegir imagen</label>
                          {formik.touched.vaucher && formik.errors.vaucher && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.vaucher}</div>
                            </div>
                          )}
                        </div>
                      </div>


                      <div className="col-md-10 col-xs-12 mt-8">
                        <label className='form-label'>Nota</label>
                        <textarea className="form-control" rows={5} placeholder="" {...formik.getFieldProps('note')}></textarea>
                        {formik.touched.note && formik.errors.note && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.note}</div>
                          </div>
                        )}
                      </div>

                      <div className="row mt-10">
                        <div className="col-md-10">

                          <div className='d-flex justify-content-end'>
                            <span>&nbsp;&nbsp;</span>
                            <button type='submit' className='btn btn-primary rounded-pill' >

                              <span className='indicator-progress' style={{ display: 'block' }}>
                                Actualizar Saldo
                              </span>

                            </button>
                          </div>


                        </div>
                      </div>

                    </div>
                  </form>
                </div>
                <div className="col-md-6 col-xs-12 mr-md-2 mb-3 d-flex align-items-center flex-column">
                  {/* Primera Card */}

                  <div className="card mb-3" style={{ width: '100%', height: '15rem', border: '3px dashed #ebebeb' }}>
                    <div className="card-body d-flex flex-column justify-content-center">
                      <p className="card-text text-center text-primary" style={{ fontSize: '1.5rem' }}><strong>

                        Saldo para Recargas de Tiempo Aire</strong></p>
                      <p className="card-text text-center" style={{ fontSize: '4rem' }}><strong>
                        ${
                          isNaN(saldoRecargas)
                            ? '0.00'
                            : parseFloat(saldoRecargas.toString()).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })
                        }
                      </strong>
                      </p>
                    </div>
                  </div>

                  {/* Segunda Card */}
                  <div className="card" style={{ width: '100%', height: '16rem', border: '3px dashed #ebebeb' }}>
                    <div className="card-body d-flex flex-column justify-content-center">
                      <p className="card-text text-center text-primary" style={{ fontSize: '1.5rem' }}><strong>Saldo para Pagos de Servicios</strong></p>
                      <p className="card-text text-center" style={{ fontSize: '4rem' }}><strong>$
                        {
                          isNaN(saldoPagos)
                            ? '0.00'
                            : parseFloat(saldoPagos.toString()).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })
                        }
                      </strong></p>
                    </div>
                  </div>

                  <div className="card mt-4" style={{ width: '100%', height: '16rem', border: '3px dashed #ebebeb' }}>
                    <div className="card-body d-flex flex-column justify-content-center">
                      <p className="card-text text-center text-primary" style={{ fontSize: '1.5rem' }}><strong>Monto de Comisiones</strong></p>
                      <p className="card-text text-center" style={{ fontSize: '4rem' }}><strong>$
                        {
                          isNaN(saldoCommission)
                            ? '0.00'
                            : parseFloat(saldoCommission.toString()).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })
                        }
                      </strong></p>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
      <LastRecordPassport customerId={Number(customerId)} registroExitoso={registroExitoso} />


    </>
  )
}
export { Passport }
